//@import "node_modules/bf-solid/_lib/solid";

body {
  font-family: Helvetica Neue, Helvetica, Arial;
}

@import "node_modules/bourbon/app/assets/stylesheets/bourbon";
@import "node_modules/bourbon-neat/app/assets/stylesheets/neat";
@import "joanna";

.hide-mobile {
  display: none;
  @include media(400px) {
    display: inline;
  }
}

.modal-default-button {
}

.modal-cancel-button {
}

.estimator-wrapper {
  padding: 0 24px 24px 24px;
  @include media(1020px) {
    padding: 0;
  }
}
.estimator {

  label {
    display: block;
  }

  .row {
    @include clearfix;
  }

  .input {
    width: 100%;
  }

  .action {
    @include clearfix;
  }
  
  .input-third {
    @include fill-parent;
    @include media(400px) {
      @include span-columns(4 of 12);
      @include omega(3n);
    }
  }
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  th {
    text-align: left;
    padding: 6px 4px;
  }

  td {
    padding: 6px 4px;
    border-top: 1px solid #ccc;
  }
}